import { gsap } from 'gsap';

export default function useGsapAnimation() {
  const fadeInOnScroll = (
    triggerElement: gsap.DOMTarget,
    element: gsap.DOMTarget,
  ): void => {
    gsap.to(element, {
      scrollTrigger: {
        trigger: triggerElement,
        start: 'top-=400 center',
      },
      opacity: 1,
      y: 0,
      duration: 0.6,
      ease: 'none',
    });
  };

  const fadeOutMultiple = (elements: HTMLElement[]): void => {
    gsap.fromTo(elements, {
      opacity: 1,
    }, {
      opacity: 0,
      duration: 0.6,
    });
  };

  const fadeInMultiple = (elements: HTMLElement[]): void => {
    gsap.fromTo(elements, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: 0.6,
    });
  };

  return {
    fadeInOnScroll,
    fadeOutMultiple,
    fadeInMultiple,
  };
}
